
/* ==========================================================================
   #FONT
   ========================================================================== */
   .font-robo {
    font-family: "Roboto", "Arial", "Helvetica Neue", sans-serif;
  }
  
  .font-poppins {
    font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
  }
  
  /* ==========================================================================
     #GRID
     ========================================================================== */
  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .row-space {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .col-2 {
    width: -webkit-calc((100% - 60px) / 2);
    width: -moz-calc((100% - 60px) / 2);
    width: calc((100% - 60px) / 2);
  }
  
  @media (max-width: 767px) {
    .col-2 {
      width: 100%;
    }
  }
  
  /* ==========================================================================
     #BOX-SIZING
     ========================================================================== */
  /**
   * More sensible default box-sizing:
   * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice

 
  /* ==========================================================================
     #BACKGROUND
     ========================================================================== */
  
  /* ==========================================================================
     #SPACING
     ========================================================================== */
  .p-t-100 {
    padding-top: 100px;
  }
  
  .p-t-180 {
    padding-top: 180px;
  }
  
  .p-t-20 {
    padding-top: 20px;
  }
  
  .p-t-10 {
    padding-top: 10px;
  }
  
  .p-t-30 {
    padding-top: 30px;
  }
  
  .p-b-100 {
    padding-bottom: 100px;
  }
  
  /* ==========================================================================
     #WRAPPER
     ========================================================================== */
  .wrapper {
    margin: 0 auto;
    width: 100%;
  }
  
  .wrapper--w960 {
    max-width: 960px;
  }
  
  .wrapper--w780 {
    max-width: 780px;
  }
  
  .wrapper--w680 {
    max-width: 680px;
  }
  
  /* ==========================================================================
     #BUTTON
     ========================================================================== */
  .btn {
    display: inline-block;
    line-height: 40px;
    padding: 0 33px;
    font-family: Poppins, Arial, "Helvetica Neue", sans-serif;
    cursor: pointer;
    color: #000d1a;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    font-size: 18px;
  }
  
  .btn--radius {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  
  .btn--pill {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
  
  .btn--green {
    background: #fff;
    border-color: #fff;
  }
  
  .btn--green:hover {
    background: #fff;
    color: #000d1a
  }
  .jello-horizontal:hover {
	animation: jello-horizontal 0.9s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-6-3 14:25:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
 @keyframes jello-horizontal {
    0% {
      transform: scale3d(1, 1, 1);
    }
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
  
  
  
  /* ==========================================================================
     #FORM
     ========================================================================== */
  input {
    outline: none;
    margin: 0;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .input-icon {
    position: absolute;
    font-size: 18px;
    color: #fff;
    right: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .input-group {
    position: relative;
    margin-bottom: 33px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .input--style-3 {
    padding: 5px 0;
    font-size: 16px;
    color: #fff;
    background: transparent;
  }

  .input--style-4 {
    padding: 5px 0;
    font-size: 16px;
    color: #fff;
    background: transparent;
  }
  
  .input--style-3::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #fff;
  }
  
  .input--style-3:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
    opacity: 1;
  }
  
  .input--style-3::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
    opacity: 1;
  }
  
  .input--style-3:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
  }
  
  .input--style-3:-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
  }
  .input--style-4::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #fff;
  }
  
  .input--style-4:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
    opacity: 1;
  }
  
  .input--style-4::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
    opacity: 1;
  }
  
  .input--style-4:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
  }
  
  .input--style-4:-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
  }
 
  
  /* ==========================================================================
     #TITLE
     ========================================================================== */
  .title {
    font-size: 2.5rem;
    text-align: center;
    color: #fff;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .title2 {
    font-size: 1.5rem;
    text-align: center;
    color: #fff;
    font-weight: 400;
    margin-bottom: 36px;
  }
  
  /* ==========================================================================
     #CARD
     ========================================================================== */
  .card {
    overflow: hidden;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
  }
  
  .card-3 {
    background: #000d1a;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    display: table;
  }
  
  .card-3 .card-heading {
    background: url("../images/home-4.jpg") top left/cover no-repeat;
    display: table-cell;
    width: 100%;
  }
  
  .card-3 .card-body {
    padding: 7rem 7rem;
    padding-bottom: 65px;
    display: table-cell;
    width: 100%;
  }
  
  @media (max-width: 767px) {
    .card-3 {
      display: block;
    }
    .card-3 .card-heading {
      display: block;
      width: 100%;
      padding-top: 400px;
      background-position: center center;
    }
    .card-3 .card-body {
      display: block;
      width: 100%;
      padding: 37px 30px;
      padding-bottom: 45px;
    }
  }
  